<template>
    <div>
        <TerminalSave></TerminalSave>
    </div>
</template>

<script>
    import TerminalSave from '@/components/terminalUpdate/storage';
    export default {
        components:{
            TerminalSave
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        
    };
</script>

<style scoped>

</style>
